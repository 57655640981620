<template>
  <div class="ml-container">

    <!-- COOKIES (MODAL) -->
    <m-modal
      :modal-title="$t('message.cookie.title')"
      body-title="BodyTitle"
      :open="showModalCookie"
      :display-close-button="closeButton"
      @update:open="showModalCookie = !showModalCookie"
    >
      <template v-slot:default>
        <p v-html="$t('message.cookie.description')" />
      </template>
      <template v-slot:footer>
        <m-button size="s" :label="$t('ui.button.btnAccept')" @click="onclickAcceptCookie(true)" />

        <m-button
          size="s"
          theme="bordered-neutral"
          :label="$t('ui.button.btnCancel')"
          @click="onclickAcceptCookie(false)"
        />
      </template>
    </m-modal>

    <!-- SELECTED STORE BUTTON -->
    <div v-if="storeSelected.code" class="ml-flexy__col--full">
      <div class="store_container">
        <m-button
          theme="bordered"
          icon="StoreLM64"
          :label="storeSelected.name"
          @click="showStoreData = true"
        />
      </div>

      <!-- STORE DETAIL (MODAL) -->
      <m-modal
        modal-title=""
        body-title="BodyTitle"
        :open="showStoreData"
        @update:open="showStoreData = !showStoreData"
      >
        <template v-slot:default>
          <h5 class="mt-heading mt-heading--s mt-heading--underline mt-heading--light">
            {{ storeSelected.name }}
          </h5>

          <div class="container_outlined mu-p-050">
            <!-- ADDRESS -->
            <div class="mu-mb-100 icon_text">
              <m-icon name="MonochromeStoreLocation64" />
              <div>
                <h4>
                  {{ $t('ui.label.store.address') }}
                </h4>
                <p v-if="storeSelected.ourStore || storeSelected.street">
                  {{ address(storeSelected) }}
                </p>
              </div>
            </div>

            <!-- CITY - PROVINCE -->
            <div v-if="storeSelected.city || storeSelected.province" class="mu-mt-100 icon_text">
              <m-icon name="Locator64" />
              <div>
                <h4>
                  {{ $t('ui.label.store.cityProvince') }}
                </h4>
                <p>
                  {{ cityProvince(storeSelected) }}
                </p>
              </div>
            </div>

            <!-- COMMUNITY -->
            <div v-if="storeSelected.comunity" class="mu-mt-100 icon_text">
              <m-icon name="Map64" />
              <div>
                <h4>
                  {{ $t('ui.label.store.community') }}
                </h4>
                <p>
                  {{ storeSelected.comunity }}
                </p>
              </div>
            </div>

            <!-- PHONE -->
            <div v-if="storeSelected.professionalPhone" class="mu-mt-100 icon_text">
              <m-icon name="ContactPhone48" />
              <div>
                <h4>{{ $t('ui.label.store.phone') }}</h4>
                <p>{{ storeSelected.professionalPhone }}</p>
              </div>
            </div>

            <!-- URL -->
            <div v-if="storeSelected.url" class="mu-mt-100 icon_text">
              <m-icon name="ContactWebsite48" />
              <div>
                <h4>
                  {{ $t('ui.label.store.webSite') }}
                </h4>
                <m-link :href="storeSelected.url">
                  <template v-slot:default>
                    {{ $t('ui.label.store.moreInfo') }}
                  </template>
                </m-link>
              </div>
            </div>
          </div>
        </template>

        <!-- STORE DETAIL FOOTER -->
        <template v-slot:footer>

          <!-- CHANGE STORE BUTTON -->
          <router-link
            :to="{
              name: 'Stores',
              params: {
                sku: skuSelected,
                changeStore: true
              }
            }"
            @click.native="changeStore"
          >
            <m-button size="s" :label="$t('ui.button.btnChangeStore')" />
          </router-link>

          <!-- CLOSE STORE DETAIL BUTTON -->
          <m-button
            size="s"
            theme="bordered-neutral"
            :label="$t('ui.button.btnClose')"
            @click="showStoreData = !showStoreData"
          />

        </template>
      </m-modal>
    </div>
    <div class="ml-flexy">
      <router-view />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { SET_STORE_SELECTED } from '@/store/actions/stores';
import { lsRemoveSelectedStore, lsStoreSelected, lsGetCookieConsent, lsSetCookie } from '@/utils/localStore';
export default {
  name: 'Layout',
  props: {
    imgLogo: {
      type: String,
      default: require('@/assets/img/logo_lm1.png')
    },
    morePro: {
      type: String,
      default: require('@/assets/img/PRO_LEROY_MERLIN.png')
    }
  },
  data() {
    return {
      titleApp: 'Catalogo PRO',
      showStoreData: false,
      showModalCookie: false,
      closeButton: false,
      stores: []
    };
  },
  computed: {
    ...mapGetters(['getDataStores', 'getDataProducts']),
    listStores() {
      return this.getDataStores('stores');
    },
    storeSelected() {
      return this.getDataStores('selected');
    },
    skuSelected() {
      return this.getDataProducts('sku');
    }
  },
  mounted() {
    const cookie = lsGetCookieConsent();
    if (!cookie) this.showModalCookie = true;
    else if (cookie && cookie.accepted) {
      this.addScriptsToHead();
    }
  },
  methods: {
    
    ...mapActions([SET_STORE_SELECTED]),
    async changeStore() {
      this.showStoreData = false;
      await this.SET_STORE_SELECTED('');
      const store = lsStoreSelected;
      if (store) lsRemoveSelectedStore();
    },

    addScriptsToHead() {
      const head = document.getElementsByTagName('head')[0];
      const script = document.createElement('script');
      script.innerHTML = `(function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', 'GTM-TQ3QB39');`;
      head.insertBefore(script, head.firstChild);
    },

    ourStore(store) {
      const { ourStore, street } = store;
      return ourStore && ourStore !== street ? ourStore : null;
    },

    address(store) {
      const { ourStore, street } = store;
      return ourStore ? ourStore : street;
    },

    cityProvince(store) {
      let infoCity = '';
      const { city, province } = store;
      if (city && province) {
        infoCity = city + '-' + province;
      } else {
        infoCity = city || province;
      }
      return infoCity;
    },

    onclickAcceptCookie(value) {
      console.log('Acepta cookies');
      const objCookie = {
        timestamp: Date.now().toString(),
        accepted: value
      };
      lsSetCookie(objCookie);
      if (value) this.addScriptsToHead();
      this.showModalCookie = false;
    }

  }
};
</script>

<style lang="scss" scoped>
.mc-button {
  width: 100%;
}

.mc-modal__footer {
  a {
    text-decoration: none !important;
  }
}

h4 {
  margin: 0 !important;
}
</style>
